import {
  SectionData,
  SectionDataItem,
} from 'js/components/controls/SelectInput/SectionData';
import React from 'react';
import debounce from 'p-debounce';
import { LoadingButton } from 'js/components/Button/LoadingButton';
import { fetchSalonNameSearchResultsDebounced } from 'js/service/searchService';
import {
  readSalonTabData,
  saveSalonTabSearch,
} from 'js/pages/HomePage/search-data';
import * as track from 'js/pages/HomePage/tracking/salonSearch';
import {
  isItemResultType,
  ItemResult,
  ItemResultData,
  makeItemResult,
  MAX_RECENT_ENTRIES_COUNT,
} from 'js/components/controls/search-item-result';
import { SearchIcon } from 'js/components/controls/SearchIcon';
import {
  CommonSearchInput,
  CommonSearchInputHandle,
} from 'js/components/controls/CommonSearchInput';
import { RecentSearchesItem } from 'js/pages/HomePage/SearchBoxTabs/RecentSearchesItem';
import { CmsHomePage } from 'js/model/cms/cms-home-page';
import { CmsCommon } from 'js/model/cms/cms-common';
import { Context } from 'js/components/LocaleWrapper';
import { useDevice } from '@treatwell/ui';
import { debounceTrackMParticleSearch } from 'js/pages/tracking/trackSearch';
import { getButtonColour } from '../search-button-colour';
import styles from '../CommonSearchTab.module.css';

interface Props {
  cmsSearch: CmsHomePage['page']['home']['search'];
  cmsCommon: CmsCommon;
}

export function SalonSearchTab(props: Props): React.ReactElement {
  const { pageData, generateUri } = React.useContext(Context);
  const languageCode = pageData.channel.languageCode;

  const { isMobile } = useDevice();

  const debouncedSearch = fetchSalonNameSearchResultsDebounced(200);

  const debouncedTrackSalonInputType = debounce(
    (value: string, resultCount: number) => {
      track.salonInputType(value, resultCount);
    },
    300
  );

  const salonSearchFunction = async (value: string): Promise<ItemResult[]> => {
    const results = await debouncedSearch(pageData, value);

    if (results.length === 0) {
      debounceTrackMParticleSearch({
        country: pageData.channel.country.countryCode,
        isWebMobile: isMobile,
        triggerContext: 'home',
        searchType: 'venue',
        searchTerm: value,
        language: pageData.channel.languageCode,
      });
    }
    /**
     * Event must be tracked in 500ms after user stopped typing, to avoid registering too many irrelevant mid-state typing events.
     * Because we are awaiting for the request to complete, which is debounced after 200ms, we simply chain fire-and-forget
     * debounced tracking call with timeout of 300ms right after results request is completed.
     */
    debouncedTrackSalonInputType(value, results.length);

    return results;
  };

  const isDestroyed = React.useRef<boolean>(false);

  const currentSalonSearchData = React.useRef<ItemResultData | null>(null);

  const currentSalonInputRef = React.useRef<CommonSearchInputHandle | null>(
    null
  );

  const [isValid, setIsValid] = React.useState<boolean>(true);

  const [recentSalonSearches, setRecentSalonSearches] = React.useState<
    RecentSearchesItem[]
  >([]);

  React.useEffect(() => {
    const { recentSalons: recentSalonSearches } = readSalonTabData(
      languageCode,
      null
    );
    setRecentSalonSearches(recentSalonSearches);

    return () => {
      isDestroyed.current = true;
    };
  }, []);

  React.useEffect(() => {
    const { lastSearch } = readSalonTabData(languageCode, null);

    if (lastSearch.salon && currentSalonInputRef.current !== null) {
      currentSalonInputRef.current?.setSelectedItemKey(
        `recent:${lastSearch.salon.entityType}:${lastSearch.salon.entityValue}`
      );
    }
  });

  const onSalonSearchChange = (
    value: string,
    selectedKey: string | null,
    data: ItemResultData | null,
    index: number
  ): void => {
    currentSalonSearchData.current = data;

    if (!isValid && data !== null) {
      setIsValid(true);
    }

    trackSalonSearchInput(value, selectedKey, index);
  };

  const trackSalonSearchInput = (
    value: string,
    selectedKey: string | null,
    index: number
  ): void => {
    if (selectedKey) {
      track.salonInputSelect(value, index);
    } else if (value === '') {
      track.salonInputClear();
    }
  };

  const goToVenuePage = (): void => {
    if (currentSalonSearchData === null) {
      return;
    }

    window.location = generateUri('venue', {
      normalisedName: currentSalonSearchData.current?.entityValue,
    });
  };

  const onSubmit = async (): Promise<void> => {
    if (currentSalonSearchData.current === null) {
      setIsValid(false);
      track.searchButtonFail('fail: invalid venue name');
      return;
    }

    saveSalonTabSearch(currentSalonSearchData.current, languageCode);

    await track.searchButtonSuccess();
    if (isDestroyed.current) {
      return;
    }

    goToVenuePage();
  };

  const recentSalons = (): SectionData<ItemResultData> => {
    const heading = props.cmsSearch['recent-searches-heading'];
    const items = recentSalonSearches
      .slice(0, MAX_RECENT_ENTRIES_COUNT)
      .map((salon) => {
        if (!isItemResultType(salon.entityType)) {
          throw new Error(`unexpected entityType: ${salon.entityType}`);
        }

        return makeItemResult(
          'recent',
          salon.entityType,
          salon.entityValue,
          salon.name
        );
      });

    return { heading, items };
  };

  const dataForNoSearch = (): SectionData<ItemResultData>[] => {
    return [recentSalons()].filter((section) => section.items.length > 0);
  };

  const getNoResultData = (): SectionData<ItemResultData>[] => {
    const { cmsSearch } = props;
    const items: SectionDataItem<ItemResultData>[] = [
      {
        type: 'label',
        label: cmsSearch['no-search-results-label'],
      },
    ];

    return [{ items }];
  };

  const { cmsCommon, cmsSearch } = props;
  const buttonColourName = props.cmsSearch['highlight-colour'];
  const buttonColour = getButtonColour(buttonColourName);

  return (
    <div className={styles.container}>
      <CommonSearchInput
        cmsCommonControls={cmsCommon.controls}
        ref={currentSalonInputRef}
        icon={SearchIcon}
        placeholder={cmsSearch['tab-venue']['venue-search-placeholder']}
        searchResultsHeading={
          cmsSearch['tab-venue']['venue-search-results-heading']
        }
        dataForNoSearch={dataForNoSearch()}
        searchFunction={salonSearchFunction}
        noResultsData={getNoResultData()}
        isErrorStyling={!isValid}
        isHotJarWhiteList
        onChange={onSalonSearchChange}
        onFocus={track.salonInputFocus}
        onBlur={track.salonInputBlur}
      />
      <div style={{ display: 'flex' }}>
        <LoadingButton
          disableLoading={!isValid}
          positioningClassname={styles.button}
          colour={buttonColour}
          label={cmsSearch['search-button']}
          onClick={onSubmit}
        />
      </div>
    </div>
  );
}
