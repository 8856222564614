import * as React from 'react';
import {
  isItemResultType,
  ItemResult,
  ItemResultData,
  ItemResultType,
  makeItemResult,
  MAX_RECENT_ENTRIES_COUNT,
} from 'js/components/controls/search-item-result';
import { SectionData } from 'js/components/controls/SelectInput/SectionData';
import { arrayWithoutNullables } from 'js/helpers/array';
import { Dictionary } from 'js/types/generic-types';
import {
  CommonSearchInput,
  CommonSearchInputHandle,
} from 'js/components/controls/CommonSearchInput';
import { CmsCommonControls } from 'js/model/cms/cms-common-controls';
import { SearchIcon } from './SearchIcon';

interface Props {
  cmsCommonControls: CmsCommonControls;
  searchFunction: (value: string) => Promise<ItemResult[]>;
  isErrorStyling: boolean;
  recentTreatments?: {
    entityType: string;
    entityValue: string;
    name: string;
  }[];
  onChange?: (
    value: string,
    selectedKey: string | null,
    data: ItemResultData | null,
    index: number
  ) => void;
  onComplete?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
}

export interface TreatmentSearchInputHandle {
  setSelectedItemKey: (selectedItemKey: string) => void;
}

export const TreatmentSearchInput = React.forwardRef(
  (
    { recentTreatments = [], ...props }: Props,
    ref: React.Ref<TreatmentSearchInputHandle>
  ) => {
    const commonSearchInputRef = React.useRef<CommonSearchInputHandle>(null);

    React.useImperativeHandle(ref, () => ({
      setSelectedItemKey(selectedItemKey: string): void {
        if (commonSearchInputRef.current === null) {
          return;
        }
        commonSearchInputRef.current.setSelectedItemKey(selectedItemKey);
      },
    }));

    function dataForNoSearch(): SectionData<ItemResultData>[] {
      return arrayWithoutNullables([
        getRecentTreatments(),
        getPopularTreatments(),
      ]).filter((section) => section.items.length > 0);
    }

    function getRecentTreatments(): SectionData<ItemResultData> | undefined {
      if (!recentTreatments) {
        return;
      }

      const heading = props.cmsCommonControls.search['recent-searches-heading'];

      if (typeof heading !== 'string') {
        return;
      }

      const items = recentTreatments
        .slice(0, MAX_RECENT_ENTRIES_COUNT)
        .map((treatment) => {
          if (!isItemResultType(treatment.entityType)) {
            throw new Error(`unexpected entityType: ${treatment.entityType}`);
          }

          return makeItemResult(
            'recent',
            treatment.entityType,
            treatment.entityValue,
            treatment.name
          );
        });

      return { heading, items };
    }

    function isValid(item: unknown): item is {
      name: string;
      normalisedName: string;
      'treatment-id'?: unknown;
    } {
      if (typeof item !== 'object' || item === null) {
        return false;
      }

      // Remove empty items & disabled items with missing normalisedNames
      return (
        Boolean((item as Dictionary<unknown>).name) &&
        Boolean((item as Dictionary<unknown>).normalisedName)
      );
    }

    function getPopularTreatments(): SectionData<ItemResultData> | undefined {
      const heading =
        props.cmsCommonControls.search['treatment-popular-heading'];
      const itemsCms =
        props.cmsCommonControls.search['treatments-popular'].treatment;

      if (typeof heading !== 'string' || !(itemsCms instanceof Array)) {
        console.warn('invalid popular treatments data in cms');
        return;
      }

      const items = itemsCms
        // filter any potentially invalid cms data
        .filter(isValid)
        .map((item) => {
          if (item['treatment-id']) {
            return makeItemResult(
              'popular',
              ItemResultType.Treatments,
              item.normalisedName,
              item.name
            );
          }
          return makeItemResult(
            'popular',
            ItemResultType.TreatmentType,
            item.normalisedName,
            item.name
          );
        });

      return { heading, items };
    }

    return (
      <CommonSearchInput
        cmsCommonControls={props.cmsCommonControls}
        ref={commonSearchInputRef}
        icon={SearchIcon}
        placeholder={props.cmsCommonControls.search['treatment-placeholder']}
        searchResultsHeading={
          props.cmsCommonControls.search['treatment-results-heading']
        }
        dataForNoSearch={dataForNoSearch()}
        searchFunction={props.searchFunction}
        isErrorStyling={props.isErrorStyling}
        isHotJarWhiteList
        isFocusOnClear={false}
        onChange={props.onChange}
        onComplete={props.onComplete}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
      />
    );
  }
);

TreatmentSearchInput.displayName = 'TreatmentSearchInput';
