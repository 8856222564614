import { captureTextToHighlight } from 'js/pages/NearMePage/capture-text-to-highlight';
import React from 'react';

export const HighlightedText = ({
  className,
  text,
}: {
  className: string;
  text: string;
}): React.ReactElement => {
  const parts = captureTextToHighlight(text);
  const highlightedText: React.ReactElement[] = parts.map<React.ReactElement>(
    (part) => <React.Fragment key={part}>{part}</React.Fragment>
  );

  for (let index = 1; index < parts.length; index += 2) {
    const textToHighlight: string = parts[index];

    highlightedText[index] = (
      <span className={className} key={`${textToHighlight}-${index}`}>
        {textToHighlight}
      </span>
    );
  }

  return <>{highlightedText}</>;
};
