import React from 'react';
import clsx from 'clsx';

import styles from './TabBar.module.css';
import { TabBarItem } from './TabBarItem';
import * as Item from './Item';

interface Props {
  items: Item.Item[];
  onTabItemClick?: (index: number) => void;
  activeIndex: number;
}

interface AnimationState {
  activeLineLeft?: number;
  activeLineRight?: number;
  isAnimateActiveLine?: boolean;
}

export function TabBar(props: Props): React.ReactElement {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const activeIndexRef = React.useRef(props.activeIndex);

  const setActiveIndex = (index: number) => {
    activeIndexRef.current = index;
  };

  const tabBarItemRefs = React.useRef<TabBarItem[]>([]);
  const animationTimeoutId = React.useRef<number>(0);
  const [isAnimateActiveLine, setIsAnimateActiveLine] =
    React.useState<boolean>(false);
  const [isActiveLineVisible, setIsActiveLineVisible] =
    React.useState<boolean>(false);

  const [activeLineLeft, setActiveLineLeft] = React.useState<number>(0);
  const [activeLineRight, setActiveLineRight] = React.useState<number>(0);

  React.useEffect(() => {
    updateActiveLinePosition(false);
    window.addEventListener('resize', onResize);
    if (document.fonts) {
      document.fonts.ready.then(onFontsReady);
    }
    setIsActiveLineVisible(true);

    return () => {
      window.removeEventListener('resize', onResize);
      clearTimeout(animationTimeoutId.current);
    };
  }, []);

  React.useEffect(() => {
    updateActiveLinePosition(true);
  }, [props.activeIndex]);

  const onTabItemClick = (target: TabBarItem) => {
    if (!props.onTabItemClick) {
      return;
    }
    setActiveIndex(target.props.index);
    props.onTabItemClick(target.props.index);
  };

  const onResize = (): void => {
    updateActiveLinePosition(false);
  };

  const onFontsReady = (): void => {
    updateActiveLinePosition(false);
  };

  const updateActiveLinePosition = (isAnimated: boolean): void => {
    const tabBarItem = tabBarItemRefs.current[activeIndexRef.current];

    if (!containerRef.current || !tabBarItem) {
      throw new Error('no container or item ref');
    }

    const containerRect = containerRef.current.getBoundingClientRect();
    const labelRect = tabBarItem.getLabelBoundingClientRect();

    const activeLineLeftTemp = labelRect.left - containerRect.left;
    const xRight = activeLineLeftTemp + labelRect.width;
    const activeLineRightTemp = containerRect.width - xRight;
    const isMoveLeft = activeLineLeft < activeLineLeftTemp;

    let animationState: Partial<AnimationState>;
    let laterAnimationState: Partial<AnimationState>;

    if (isMoveLeft) {
      animationState = { activeLineRight: activeLineRightTemp };
      laterAnimationState = { activeLineLeft: activeLineLeftTemp };
    } else {
      animationState = { activeLineLeft: activeLineLeftTemp };
      laterAnimationState = { activeLineRight: activeLineRightTemp };
    }
    animationState.isAnimateActiveLine = isAnimated;
    cancelAnimationFrame(animationTimeoutId.current);
    if (isAnimated) {
      setIsAnimateActiveLine(animationState.isAnimateActiveLine);
      animationState.activeLineLeft
        ? setActiveLineLeft(animationState.activeLineLeft)
        : setActiveLineRight(animationState.activeLineRight!);

      const start = new Date().getTime();
      const animate = () => {
        const elapsed = new Date().getTime() - start;
        if (elapsed > 200) {
          laterAnimationState.activeLineLeft
            ? setActiveLineLeft(laterAnimationState.activeLineLeft)
            : setActiveLineRight(laterAnimationState.activeLineRight!);
        }
        animationTimeoutId.current = requestAnimationFrame(animate);
      };

      window.requestAnimationFrame(animate);
    } else {
      setIsAnimateActiveLine(animationState.isAnimateActiveLine);
      setActiveLineLeft(
        animationState.activeLineLeft
          ? animationState.activeLineLeft
          : laterAnimationState.activeLineLeft!
      );
      setActiveLineRight(
        animationState.activeLineRight
          ? animationState.activeLineRight
          : laterAnimationState.activeLineRight!
      );
    }
  };

  const itemElements = props.items.map((item: Item.Item, index: number) => {
    const isActive = index === props.activeIndex;

    return (
      <TabBarItem
        key={item.label}
        ref={(ref: TabBarItem) => {
          tabBarItemRefs.current[index] = ref;
        }}
        isActive={isActive}
        index={index}
        icon={item.icon}
        label={item.label}
        onClick={onTabItemClick}
      />
    );
  });

  return (
    <div ref={containerRef} className={styles.tabBar}>
      {itemElements}

      <div
        className={clsx(styles.activeUnderline, {
          [styles.animatedUnderline]: isAnimateActiveLine,
          [styles.activeUnderlineVisible]: isActiveLineVisible,
        })}
        style={{
          left: activeLineLeft,
          right: activeLineRight,
        }}
      />
    </div>
  );
}
