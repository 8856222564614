interface Snowplow {
  // https://docs.snowplowanalytics.com/docs/collecting-data/collecting-from-own-applications/javascript-tracker/advanced-usage/methods-which-can-be-used-from-within-a-callback/
  cfn: {
    getDomainUserId: () => string;
  };
}

declare global {
  interface Window {
    // snowplowSafe is a function that can safely be called
    // regardless of whether snowplow has loaded yet.
    //
    // see src/js/components/Head/snowplow.tsx
    snowplowSafe: (sp: (sn: Snowplow) => void) => Snowplow;
  }
}

export function snowplowReady(): Promise<Snowplow> {
  return new Promise((resolve) =>
    window.snowplowSafe(function (this: Snowplow): void {
      resolve(this);
    })
  );
}

type SnowplowCookieOptions = {
  cookies?: string;
  cookieName?: string;
};

/*
 * Function to extract the Snowplow Domain User Information from the first-party cookie set by the Snowplow JavaScript Tracker
 *
 * @link https://docs.snowplow.io/docs/collecting-data/collecting-from-own-applications/javascript-trackers/web-tracker/cookies-and-local-storage/getting-cookie-values/#domain-user-information
 *
 * @param string cookieName (optional) The value used for "cookieName" in the tracker constructor argmap
 * (leave blank if you did not set a custom cookie name)
 *
 * @return string or null The ID string if the cookie exists or null if the cookie has not been set yet
 */
export const getSnowplowDuid = (options?: SnowplowCookieOptions) => {
  const matcher = new RegExp(
    `${options?.cookieName ?? '_sp_'}id\\.[a-f0-9]+=([^;]+);?`
  );
  const match = (options?.cookies ?? document.cookie).match(matcher);
  if (match && match[1]) {
    const split = match[1].split('.');
    return {
      domain_userid: split[0],
      domain_sessionidx: split[2],
      domain_sessionid: split[5],
    };
  }
  return null;
};
