import { hasChannelFeature } from 'js/helpers/channel-feature';
import { formatPrice } from 'js/helpers/price/priceUtilities';
import { PromotionInfoResponse } from 'js/service/referralService';
import { ChannelOutput } from 'js/model/rainbow/content/ChannelOutput';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import React, { useContext, useEffect, useRef } from 'react';
import { Text } from '@treatwell/ui';
import { Context } from 'js/components/LocaleWrapper';

import {
  trackUserExitsPhoneInputModal,
  trackUserExitsOTPInputModal,
  trackUserSubmitsPhoneNumber,
  trackDuplicatePhoneNumber,
  trackResend,
  trackOTPExpiredOrInvalid,
  trackMaxRequestsReachedOTPInputModal,
  trackOTPLimit,
  trackInvalidPhoneNumber,
  trackMaxRequestsReachedPhoneInputModal,
  trackSubmit,
} from '../tracking/referral';
import styles from './GenericError.module.css';

const getOTPi18n = (
  t: TFunction,
  promotionInfo: PromotionInfoResponse,
  channel: ChannelOutput
) => {
  const refereeValue = formatPrice(
    promotionInfo.rewardAmountForReferee,
    channel
  );

  return {
    phoneInputModal: {
      close: t('referral.phoneInputModal.close'),
      continue: t('referral.phoneInputModal.continue'),
      info: t('referral.phoneInputModal.info'),
      title: t('referral.phoneInputModal.title', {
        value: refereeValue,
      }),
      countryCode: t('referral.phoneInputModal.countryCode'),
      telephone: t('referral.phoneInputModal.telephone'),
      error: {
        invalid: t('referral.phoneInputModal.error.invalid'),
        required: t('referral.phoneInputModal.error.required'),
        duplicate: t('referral.phoneInputModal.error.duplicate'),
        maxAttempts: t('referral.phoneInputModal.error.maxAttempts'),
        generic: ({ url }: { url: string }) => (
          <Text as="p" className={styles.error} type="captionHeavy">
            {t<string>('referral.phoneInputModal.error.generic.text')}{' '}
            <a href={url}>
              {t<string>('referral.phoneInputModal.error.generic.link')}
            </a>
          </Text>
        ),
      },
      warning: t('referral.phoneInputModal.warning'),
    },
    otpInputModal: {
      changeNumber: t('referral.otpInputModal.changeNumber'),
      confirm: t('referral.otpInputModal.confirm'),
      enterCode: t('referral.otpInputModal.enterCode'),
      extraInfo: t('referral.otpInputModal.extraInfo'),
      label: t('referral.otpInputModal.label'),
      resend: t('referral.otpInputModal.resend'),
      resendAvailableIn: t('referral.otpInputModal.resendAvailableIn'),
      title: t('referral.otpInputModal.title'),
      close: t('referral.otpInputModal.close'),
      error: {
        generic: t('referral.otpInputModal.error.generic'),
        invalid: t('referral.otpInputModal.error.invalid'),
        maxAttempts: t('referral.otpInputModal.error.maxAttempts'),
      },
    },
  };
};

export const useReferralOTP = (container: string, onComplete: () => void) => {
  const { pageData, channel } = useContext(Context);
  const otpTriggered = useRef(false);
  const { t } = useTranslation();

  const referralCode = pageData.pageParameters?.referral?.[0];
  const referralEnabled = hasChannelFeature(
    channel.channelFeatures,
    'referral_web'
  );
  const promotionInfo = pageData.homepage.referralPromotionInfo;

  useEffect(() => {
    if (
      otpTriggered.current ||
      !referralCode ||
      !referralEnabled ||
      !promotionInfo
    ) {
      return;
    }
    import(/* webpackChunkName: "tw-ui-otp" */ '@treatwell/ui/otp').then(
      (otpModule) => {
        const otp = new otpModule.OTP({
          container,
          i18n: getOTPi18n(t, promotionInfo, channel),
          locale: channel.locale,
          languageCode: channel.locale.split('_')[0],
          countryCode: channel.country.countryCode,
          events: {
            onDismiss: () => {
              if (otp.getCurrentModal() === 'phone') {
                trackUserExitsPhoneInputModal();
                otp.destroy();
              }
              if (otp.getCurrentModal() === 'otp') {
                trackUserExitsOTPInputModal();
              }
            },
            onRequestError: (error) => {
              if (typeof error === 'number') {
                return;
              }
              if (error.reason === 'user limit has been exceeded') {
                if (otp.getCurrentModal() === 'phone') {
                  trackMaxRequestsReachedPhoneInputModal();
                }
                if (otp.getCurrentModal() === 'otp') {
                  trackMaxRequestsReachedOTPInputModal();
                }
              }
            },
            onVerifyError: (error) => {
              if (typeof error === 'number') {
                return;
              }
              if (
                error.reason === 'otp expired' ||
                error.reason === 'otp invalid'
              ) {
                trackOTPExpiredOrInvalid();
              }
              if (error.reason === 'max attempts exceeded') {
                trackOTPLimit();
              }
            },
            onInvalidPhoneNumber: (reason?: string) => {
              if (reason === 'DUPLICATE') {
                trackDuplicatePhoneNumber();
              }
              if (reason === 'INVALID') {
                trackInvalidPhoneNumber();
              }
            },
            onSubmitPhoneNumber: () => {
              trackUserSubmitsPhoneNumber();
            },
            onResendCode: () => {
              trackResend();
            },
            onSubmitCode: () => {
              trackSubmit();
            },
            onComplete: () => {
              onComplete();
              otp.destroy();
            },
          },
          apiConfig: {
            requireOTP: {
              url: '/api/v2/referral/otp/required',
            },
            requestOTP: {
              url: '/api/v1/otp',
              requestBody: { context: 'referral' },
            },
            verifyOTP: {
              url: '/api/v1/otp/verification',
            },
            validatePhoneNumber: {
              url: '/checkout-api/validate-telephone-number',
            },
          },
        });
        otp.trigger();
        otpTriggered.current = true;
      }
    );
  }, [
    channel,
    container,
    onComplete,
    promotionInfo,
    referralCode,
    t,
    referralEnabled,
  ]);
};
