import React from 'react';
import { Week } from './Week';
import styles from './Datepicker.module.css';
import { getMonthViewStart, getMonthViewWeeks } from './date-utils';

interface Props {
  month: Date;
  onDateSelect: (selected: string, isAvailable: boolean) => void;
  availableDates: string[];
  bookingStart: string | null;
  bookingEnd: string | null;
  daySquareSize: string | null;
}

const getWeekAvailability = (
  week: string[],
  availableDates: string[] = []
): string[] => week.filter((day) => availableDates.indexOf(day) > -1);

export const MonthView = ({
  month,
  onDateSelect,
  availableDates,
  bookingStart,
  bookingEnd,
  daySquareSize,
}: Props) => {
  const weeks = getMonthViewWeeks(getMonthViewStart(month, 1));

  return (
    <div className={styles.monthView}>
      {weeks.map((week, index) => (
        <Week
          key={index}
          days={week}
          visibleMonth={month}
          onDateSelect={onDateSelect}
          availableDates={getWeekAvailability(week, availableDates)}
          bookingStart={bookingStart}
          bookingEnd={bookingEnd}
          daySquareSize={daySquareSize}
        />
      ))}
    </div>
  );
};
