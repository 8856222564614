import { NearMeFooter } from 'js/components/NearMeFooter';
import { createPropositionSlotsViewModel } from 'js/model/view/proposition-slots/proposition-slots';
import React from 'react';
import { IconHair, IconVenue, useModal } from '@treatwell/ui';
import { OTPConfirmation } from 'js/components/OTPConfirmation/OTPConfirmation';
import { useTrackPageAndSessionView } from 'js/hooks/useTrackPageAndSessionView';
import { SalesforceFooter } from 'js/components/SalesforceFooter/SalesforceFooter';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { Context } from '../../components/LocaleWrapper';
import { B2BBanner } from './B2BBanner';
import { HeroBanner } from './HeroBanner';
import styles from './HomePage.module.css';
import { MerchandisingSlots } from './MerchandisingSlots';
import { PropositionSlotsCarousel } from './PropositionSlotsCarousel';
import { RecommendedVenuesController } from './RecommendedVenues/RecommendedVenuesContoller';
import { readLastSearchTab, SALON, TREATMENT } from './search-data';
import { TabBox } from './TabBox/TabBox';
import * as track from './tracking/tracking';
import { useReferralOTP } from './hooks/useReferralOTP';
import { useError } from './hooks/useError';
import { StoryStreamWidget } from './StoryStream/StoryStreamWidget';

export default function HomePage(): React.ReactElement {
  const [activeTabBoxIndex, setActiveTabBoxIndex] = React.useState<number>(0);
  const { i18n, getMediaUri, pageData, generateUri, channel } =
    React.useContext(Context);
  const { openModal, modalProps } = useModal({ width: 650 });
  const isStoryStreamExperimentActive =
    useFeatureValue('ab-web-home-storystream-module', 'none') === 'variant-1';
  useReferralOTP('#otp', openModal);
  useError(pageData);
  useTrackPageAndSessionView();
  const chatEnabled = channel.channelFeatures.includes('chatbot_support_web');

  React.useEffect(() => {
    const availableTabs = [TREATMENT, SALON];
    const lastSearchTab = readLastSearchTab('treatment');
    const lastSearchTabIndex = availableTabs.indexOf(lastSearchTab);
    setActiveTabBoxIndex(lastSearchTabIndex);
  }, []);

  const onTabBoxTabItemClick = (tabIndex: number) => {
    setActiveTabBoxIndex(tabIndex);
    track.tabSelect(tabIndex);
  };

  const renderHeroBanner = () => {
    const getCmsMediaUri = (key: string) => getMediaUri(i18n(key));

    const tabItems = [
      {
        label: i18n('page.home.search.tab-treatments.tab-label'),
        icon: <IconHair size={24} />,
      },
      {
        label: i18n('page.home.search.tab-venue.tab-label'),
        icon: <IconVenue size={24} />,
      },
    ];

    return (
      <HeroBanner
        headline={i18n('page.home.hero-banner.headline-text')}
        headlineVerticalAlignment={i18n(
          'page.home.hero-banner.headline-vertical-alignment'
        )}
        backgroundColor={i18n('page.home.hero-banner.background-color')}
        backgroundImages={{
          mobile2x: getCmsMediaUri(
            'page.home.hero-banner.image-mobile-2x-path'
          ),
          desktop1x: getCmsMediaUri(
            'page.home.hero-banner.image-desktop-1x-path'
          ),
          desktop2x: getCmsMediaUri(
            'page.home.hero-banner.image-desktop-2x-path'
          ),
        }}
      >
        <TabBox
          items={tabItems}
          activeIndex={activeTabBoxIndex}
          onTabItemClick={onTabBoxTabItemClick}
          isRenderHiddenTabs
        />
      </HeroBanner>
    );
  };

  return (
    <>
      {renderHeroBanner()}

      {isStoryStreamExperimentActive && <StoryStreamWidget />}

      <RecommendedVenuesController
        pageData={pageData}
        generateUri={generateUri}
      />

      <PropositionSlotsCarousel
        {...createPropositionSlotsViewModel(pageData.cms['proposition-slots'])}
      />

      <MerchandisingSlots
        slots={pageData.cms.page.home['merchandising-slots'].slot || []}
      />

      <B2BBanner cms={pageData.cms.page.home['b2b-banner']} />

      <div className={styles.cityBlocksArea}>
        <div className={styles.pageContents}>
          <NearMeFooter />
        </div>
      </div>

      {chatEnabled && (
        <div className={styles.salesforceWrapper}>
          <SalesforceFooter className={styles.salesforce} />
        </div>
      )}

      {pageData.homepage.referralPromotionInfo && (
        <OTPConfirmation
          promotionInfo={pageData.homepage.referralPromotionInfo}
          channel={channel}
          modalProps={modalProps}
        />
      )}

      <div id="otp" />
    </>
  );
}
